import React, { useState, useContext, useEffect, useMemo } from "react";
import { WebsiteContext } from "../../../context/WebsiteContext";

//hooks
import useInput from "../../../hooks/useInput";
//components
import Url from "./urlForm";
import Supporting from "./supportingInfoForm";
import Info from "./aboutCompany";
import Stars from "../../../Assets/Stars.png";
//utils
import { calcProgress } from "../../../utils/progress";

const Form = (props) => {
  //?State
  const ctx = useContext(WebsiteContext);
  const { setPage, page, setIsScraping, isScraping, tables, error } = ctx;
  const [scrapeData, setScrapeData] = useState(null);
  const [progress, setProgress] = useState(0);
  //?Inputs
  //First form
  const related_1 = useInput((value) => {
    return value !== "";
  }, ctx.formInfo.related_1);
  const related_2 = useInput((value) => value !== "", ctx.formInfo.related_2);
  const related_3 = useInput((value) => value !== "", ctx.formInfo.related_3);

  const homepage = useInput((value) => value !== "", ctx.formInfo.homepage);
  const serviceUrl = useInput((value) => value !== "", ctx.formInfo.serviceUrl);
  const about = useInput((value) => value !== "", ctx.formInfo.about);
  //Second form
  const supporting = useInput((value) => value !== "", ctx.formInfo.supporting);
  //third form
  const companyName = useInput(
    (value) => value !== "",
    ctx.formInfo.companyName
  );
  const industry = useInput((value) => value !== "", ctx.formInfo.industry);
  const service = useInput((value) => value !== "", ctx.formInfo.service);
  const offer = useInput((value) => value !== "", ctx.formInfo.offer);
  const cta = useInput((value) => value !== "", ctx.formInfo.cta);
  const keywords = useInput((value) => value !== "", ctx.formInfo.keywords);
  const allInputsInputted = !!(
    companyName.value &&
    industry.value &&
    service.value &&
    offer.value &&
    cta.value &&
    keywords.value
  );

  //?combining all the info
  const websiteInfo = useMemo(
    () => ({
      related_1: related_1.value,
      related_2: related_2.value,
      related_3: related_3.value,
      homepage: homepage.value,
      serviceUrl: serviceUrl.value,
      about: about.value,
      companyName: companyName.value,
      industry: industry.value,
      service: service.value,
      offer: offer.value,
      cta: cta.value,
      keywords: keywords.value,
      supporting: supporting.value,
    }),
    [
      related_1.value,
      related_2.value,
      related_3.value,
      homepage.value,
      serviceUrl.value,
      about.value,
      companyName.value,
      industry.value,
      service.value,
      offer.value,
      cta.value,
      keywords.value,
      supporting.value,
    ]
  );
  //? progress bar
  useEffect(() => {
    setProgress(calcProgress(websiteInfo));
  }, [websiteInfo]);
  //? Which form to display
  const pages = [
    <Url
      related_1={related_1}
      related_2={related_2}
      related_3={related_3}
      homepage={homepage}
      service={serviceUrl}
      about={about}
    />,
    <Supporting ctx={ctx} supporting={supporting} />,
    <Info
      ctx={ctx}
      companyName={companyName}
      industry={industry}
      service={service}
      offer={offer}
      cta={cta}
      keywords={keywords}
    />,
  ];

  if (ctx.error && page !== 0) {
    setPage(0);
  }

  const fetchScrape = async (url) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/scrape`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ url }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error in scraping the content, check your URL`);
      }

      const data = await response.json();

      return data;
    } catch (e) {
      throw new Error(e);
    }
  };
  const scrape = async (info) => {
    setIsScraping(true);
    const { related_1, related_2, related_3, homepage, serviceUrl, about } =
      info;
    try {
      const relatedContent1 = related_1 && (await fetchScrape(related_1));
      const relatedContent2 = related_2 && (await fetchScrape(related_2));
      const relatedContent3 = related_3 && (await fetchScrape(related_3));
      const homepageContent = homepage && (await fetchScrape(homepage));
      const serviceContent = serviceUrl && (await fetchScrape(serviceUrl));
      const aboutContent = about && (await fetchScrape(about));
      const object = {
        relatedContent1,
        relatedContent2,
        relatedContent3,
        homepageContent,
        serviceContent,
        aboutContent,
      };

      ctx.setScrapeInfo(object);
      setIsScraping(false);
      setScrapeData(object);
    } catch (e) {
      //console.log(e.message)
      ctx.setError(e.message);
      setIsScraping(false);
    }
  };

  const handleClick = async (e, nav) => {
    e.preventDefault();
    if (page === 0) {
      if (
        related_1.urlError ||
        related_2.urlError ||
        related_3.urlError ||
        homepage.urlError ||
        serviceUrl.urlError ||
        about.urlError
      ) {
        ctx.setError("Only input one URL per input");
      }
      ctx.setFormInfo((prevState) => ({
        ...prevState,
        ...websiteInfo,
      }));
      scrape(websiteInfo);
    } else if (page === 2) {
      if (allInputsInputted) {
        ctx.setLoading(true);
      } else {
        if (!companyName.value) {
          companyName.setHasError(true);
        }
        if (!industry.value) {
          industry.setHasError(true);
        }
        if (!service.value) {
          service.setHasError(true);
        }
        if (!offer.value) {
          offer.setHasError(true);
        }
        if (!cta.value) {
          cta.setHasError(true);
        }
        if (!keywords.value) {
          keywords.setHasError(true);
        }
      }
    }
    if (nav !== undefined) {
      if (isScraping && nav === 0) {
      } else if (!isScraping && nav === 0) {
        setPage(nav);
      } else if (nav === 1 || nav === 2) {
        setPage(nav);
      }
    } else {
      setPage((currPage) => {
        if (currPage !== 3) {
          if (currPage === 2 && !allInputsInputted) {
            return currPage;
          }
          return currPage + 1;
        } else {
          return currPage;
        }
      });
    }

    ctx.setFormInfo((prevState) => ({
      ...prevState,
      ...websiteInfo,
    }));
  };

  useEffect(() => {
    if (scrapeData !== null && page === 3 && !error) {
      if (allInputsInputted) {
        props.handleSubmit(websiteInfo, scrapeData);
      }
    }
  }, [scrapeData, props, page, websiteInfo, allInputsInputted, error]);

  return (
    <>
      {!tables && (
        <div
          className={`
          ${ctx.loading && "hidden"}
          mt-[10vh]
          flex
          flex-col
          items-center
          justify-center
          `}
        >
          <div
            className="
            mx-2
            mb-5
            text-center
            leading-tight
                z-10
                relative
                flex gap-2 
                flex-wrap
                items-center
                justify-center
          "
          >
            <img src={Stars} alt="stars" className="hidden xs:block" />
            <h1
              className="
          text-slate-50
            text-[2.2rem]
            font-bold
            sm:whitespace-nowrap
            "
            >
              Google Ads Generator
            </h1>
          </div>
          <div
            className="
        sm:h-[200px]
        text-slate-50
        flex
        item-center
        justify-center

          "
          >
            {page === 0 && (
              <p className="w-[90vw] md:w-[70vw] max-w-[400px] ">
                'Homepage URL': Enter your main website URL. <br />
                'Service page URL': Input your service/product page URL. <br />
                'About page URL': Add your 'About Us' page URL. <br />
              </p>
            )}
            {page === 1 && (
              <p className="   w-[90vw] md:w-[70vw] max-w-[400px] ">
                Provide additional details about your service/product. <br />{" "}
                Highlight unique features, benefits, and why customers should
                choose you. <br />
                Keep it concise, within 2000 characters.
              </p>
            )}
            {page === 2 && (
              <p className="       w-[90vw] md:w-[70vw] !max-w-[550px]">
                'Company name': Input your official company name. <br />
                'Industry': Specify the industry your company belongs to. <br />
                'Service/product name': Indicate the service/product you're
                advertising. <br />
                'Offer': Mention any special deals or offers related to your
                service/product. <br />
                'Call to action': Write a compelling phrase to prompt customer
                action. <br />
                'Keywords': Add relevant search terms related to your
                service/product.
              </p>
            )}
          </div>
          <div>
            <div
              className="
              rounded-t-2xl
            overflow-hidden
            w-full 
            bg-[#110b21]
            text-[#655199]
            font-semibold
            flex 
            text-center
     
            "
            >
              <p
                className={`py-5 h-full flex-1  cursor-pointer ${
                  page === 0 && "bg-[#655199] text-slate-50"
                } ${isScraping && "!cursor-not-allowed"}`}
                onClick={(e) => {
                  handleClick(e, 0);
                }}
              >
                Scraper
              </p>
              <p
                className={`py-5 h-full flex-1  cursor-pointer ${
                  page === 1 && "bg-[#655199] text-slate-50"
                }`}
                onClick={(e) => {
                  handleClick(e, 1);
                }}
              >
                Context
              </p>
              <p
                className={`py-5 h-full flex-1  cursor-pointer ${
                  page === 2 && "bg-[#655199] text-slate-50"
                }`}
                onClick={(e) => {
                  handleClick(e, 2);
                }}
              >
                Product/Service
              </p>
            </div>
            <form
              className="
        w-[90vw]
        sm:w-[60vw]
        max-w-[450px]
        px-4
        py-5
        mb-[100px]
        flex
        flex-col 
        items-center 
        justify-center 
        bg-[#fff]
        drop-shadow-lg  
        relative
        rounded-b-2xl
        "
            >
              <div className="bg-gray-300 h-[4px] w-full mb-3">
                <div
                  className={`h-full rounded-2xl`}
                  style={{
                    width: `${progress}%`,
                    backgroundColor: `${
                      progress === "100" ? "#95FA8B" : "#5483ED"
                    }`,
                  }}
                ></div>
              </div>
              {pages[page]}
              <button
                className={` 
            ${
              page === 2
                ? `bg-[#8066c0] ${
                    (!companyName.value ||
                      !industry.value ||
                      !service.value ||
                      !offer.value ||
                      !cta.value ||
                      !keywords.value) &&
                    "cursor-not-allowed"
                  }`
                : "bg-[#374151]"
            }
                
          mt-5 
            w-full
          rounded-sm
          text-slate-50
          py-2`}
                onClick={handleClick}
              >
                {page !== 2 ? "Next" : "Create Ads"}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Form;
